import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import { useStringsContext } from "../contexts/strings-context";
import { SEO } from "../components/SEO";
import { PageProps } from "../types/global";
import HeroImage from "~/components/home/heroImage";
import Holiday from "~/components/home/holiday";
import Divider from "~/components/home/divider"
import PerfectHoliday from "~/components/home/perfectHoliday";
import Review from "~/components/home/review";
import Destination from "~/components/home/destination";
import destination from "../assets/background/destination.jpeg";
import review from "../assets/background/rev.jpeg";

export const query = graphql`
    query($language: String) {
        strings(language: { eq: $language }) {
            ...LayoutStringsFragment
            welcome
            tour
            desc
          }
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: nowrap;
    @media (max-width: 1250px) {
        position: relative;
        z-index: 2;
    }
`;

const ContainerImage  = styled.div`
  width: 100%;
  background-image: url("${destination}");
  background-repeat: no-repeat;
  background-size: 100%;
  @media(max-width: 900px) {
    background-image: none;
  }
  padding-top: 7rem;
`;

const ContainerImageRev  = styled.div`
  width: 100%;
  background-image: url("${review}");
  background-repeat: no-repeat;
  background-size: 100%;
  vertical-align: middle;

  margin-top: -5rem;
  @media(max-width: 900px) {
    background-image: none;
  }
  padding-top: 7rem;
`;

const IndexPage: React.FunctionComponent<PageProps> = (props) => {
  
  const strings = useStringsContext();

  return (
    <>
      <SEO
        lang={props.pageContext.language}
        title="WANDERLUST TOURISM - HOME"
        description=""
        keywords={["WANDERLUST","Tourism","wanderlust","wonderlust","tour","travel"]}
        url={props.location.pathname}
      />
      <Container>
        <HeroImage/>
        <Holiday/>
        <Divider/>
        <ContainerImage>
          <Destination />
        </ContainerImage>
        <PerfectHoliday/>
        <ContainerImageRev>
          <Review />
        </ContainerImageRev>
        
      </Container>
    </>
  )
}

export default IndexPage

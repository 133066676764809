import React from "react"
import { graphql, useStaticQuery } from "gatsby";
import styled, { keyframes } from "styled-components";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/skyblue';
import '@splidejs/react-splide/css/sea-green';

// or only core styles
import '@splidejs/react-splide/css/core';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

export const query = graphql`
{   
  heroImage:
  allContentfulHeroImage(filter: {node_locale: {eq: "en-US"}}) {
    nodes {
      url
      headerText
      text
      desc
      heroImage {
        gatsbyImageData
      }
    }
  }
}`;


const Img = styled(GatsbyImage)`
  width: 100%;
  height: 70vh;
  object-fit: cover;
  background-position: 10% 40%;
  @media(max-width: 720px) {
    height: 50vh;
  }
`;
const Display = styled.div`
   @media(max-width: 720px) {
    display: none;
  } 
`;

const SliderStyle = styled(Carousel)` 
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  z-index: -1;
`;

const showup = keyframes`
  0% {opacity:0;}
  20% {opacity:1;}
  80% {opacity:1;}
  100% {opacity:0;}
`
const slidein = keyframes`
   0% { margin-left:550px; }
  20% { margin-left:550px; }
  35% { margin-left:0px; }
  100% { margin-left:0px; }
`
const reveal = keyframes`
  0% {opacity:0;width:100%;}
  20% {opacity:1;width:100%;}
  30% {width:100%;}
  80% {opacity:1;}
  100% {opacity:0;width:100%;}
`
const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  top: 0;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 2rem;
  @media(max-width: 720px) {
    gap: 0;
  }

div:first-of-type { 
  animation: ${showup} 7s infinite;
}

div:last-of-type {
  width:0px;
  animation: ${reveal} 7s infinite;
}

div:last-of-type span {
  animation: ${slidein} 7s infinite;
}
span {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-flow: column;
    gap: 2rem;
}
`;

const Text = styled.p`
  font-size: 6rem;
  font-family: 'sans-serif';
  @media(max-width: 720px) {
    font-size: 3rem;
  }
`;
const TextHeader = styled.p`
  font-size: 3.5rem;
  font-family: 'Dancing Script', cursive;
  font-weight: 700;
  @media(max-width: 720px) {
    font-size: 2rem;
  }
`;
const Desc = styled.p`
  font-size: 1.5rem;
  padding: 0 2rem;
  text-align: center;
  @media(max-width: 720px) {
    font-size: 1rem;
  }
`;

const HeroImage: React.FC = () => {
  const { heroImage } = useStaticQuery(query);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    draggable: true,
    arrows: false
  };
  return (
    <>
      {/* <SliderStyle options = {{ rewind: true,
                perPage: 1,
                arrows: false,
                speed: 400,
                perMove: 1,
                pagination: false,
                drag: true,
                width: "100vw",
                padding: "0",
                autoplay: true,
                type: 'slide'}}> */}
                <SliderStyle showArrows={false} infiniteLoop={true} autoPlay={true} showThumbs={false} showIndicators={false} >
        {heroImage.nodes.map((heroImages: {
          headerText?: string; url: any; text?: string; desc?: string; heroImage: {gatsbyImageData: IGatsbyImageData};
        }) => (
          <>
            <Img image={heroImages.heroImage.gatsbyImageData} alt="" />
            <TextContainer>
                
                <Display>
                  <span>
                    <TextHeader>{heroImages.headerText}</TextHeader>
                    <Text>{heroImages.text}</Text>
                    <Desc>{heroImages.desc}</Desc>
                    </span>
                </Display>
            </TextContainer>
          </>
        ))}
        </SliderStyle>
      {/* </SliderStyle> */}
    </>
  )
}
export default HeroImage;
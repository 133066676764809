import React, { FC, PropsWithChildren } from "react"
import styled, { css } from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { Cursive, Description, Normal, TextContainer } from "../typography/typography";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/skyblue';
import '@splidejs/react-splide/css/sea-green';

// or only core styles
import '@splidejs/react-splide/css/core';
const TextContainerM = styled(TextContainer)`
  margin: 2rem 0;
`;
const Row = styled(Splide)`
    display: flex;
    flex-flow: row nowrap;
    padding: 5rem;
    @media(max-width: 500px) {
        padding: 0;
    }
`;
const CardImage = styled(GatsbyImage)`
    width: 20rem;
    height: 20rem;
    border-radius: 50%;
    position: relative;
    transform-origin: 65% 75%;
    transition: transform 1s, filter .5s ease-out;
    object-fit: fill;
    :hover {
        transform: scale(1.1);
    }
    @media(max-width: 500px) {
        width: 10rem;
        height: 10rem;
    }
`;
const Container = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    align-content: center;
    height: 25rem;
    @media(max-width: 500px) {
        height: 20rem;
    }
`;

const Destination: React.FC = () => {
    const { destination } = useStaticQuery(query);
    return (
        <>
            <TextContainerM>
                <Cursive>Choose Your</Cursive>
                <Normal>Destination</Normal>
                <Description color="black">I hope your vacation gives you the pleasure you need so that you don’t have to think of any problems back at home.  Enjoy your vacation.</Description>
            </TextContainerM>
            <Row options = {{ rewind: true,
                perPage: 3,
                speed: 400,
                perMove: 3,
                drag: true,
                autoplay: true,
                breakpoints: {
                    1200: {
                        perPage: 2,
                        perMove: 1,
                    },
                    900: {
                        perPage: 1,
                        perMove: 1,
                    }
                },
                type: 'loop'}} >            
            {destination.nodes.map((detail: {
                destinationImage: {gatsbyImageData: IGatsbyImageData}; destination?: string;
            }) => (
                <SplideSlide>
                    <Container>
                    <CardImage image={detail.destinationImage.gatsbyImageData} alt={""}></CardImage>
                    <Cursive style= {{color: "white", position:"absolute", left:"0", right:"0"}}>{detail.destination}</Cursive>
                    {/* <p>{renderRichText(detail.reviewtest1, options)}</p> */}
                </Container>
                </SplideSlide>
            ))}
            </Row>
        </>
    )
}
export default Destination;

export const query = graphql`
{  
        destination:
        allContentfulDestination {
    nodes {
      destination
      destinationImage {
        gatsbyImageData
      }
    }
  }
}`;
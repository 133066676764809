import React, { FC } from "react"
import styled from "styled-components";

const Icon = styled.img`
    width: 4rem;
    height: 4rem;
`;
const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    gap: 5rem;
    margin: 5rem 0;
`;

const Text = styled.div`

`;

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    align-items: center;
    gap: 1rem;
`;

const Divider: React.FC = () => {

    return (
        <>
            <Container>
                <IconContainer>
                    <Icon src="https://ik.imagekit.io/wanderlust/home/camera_v5QzK2S0A.png?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1659071768071" alt="camera">
                    </Icon>
                    <Text>Create Wonderful Memories</Text>
                </IconContainer>

                <IconContainer>
                    <Icon src="https://ik.imagekit.io/wanderlust/home/visa_R_-ZS9Dx6.png?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1659413992361" alt="camera">
                    </Icon>
                    <Text>Insure your Visa</Text>
                </IconContainer>

                <IconContainer>
                    <Icon src="https://ik.imagekit.io/wanderlust/home/gift-card_TBg1CvOmm.png?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1659071767002" alt="camera">
                    </Icon>
                    <Text>Gift your loved ones</Text>
                </IconContainer>

                <IconContainer>
                    <Icon src="https://ik.imagekit.io/wanderlust/home/airplane-ticket_Tea82reuM.png?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1659071766150" alt="camera">
                    </Icon>
                    <Text>Ticket Booking</Text>
                </IconContainer>

                <IconContainer>
                    <Icon src="https://ik.imagekit.io/wanderlust/home/airplane_Y3gKkiYTJ.png?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1659071766151" alt="camera">
                    </Icon>
                    <Text>Travel Around the World</Text>
                </IconContainer>

            </Container>

        </>
    )
}
export default Divider;
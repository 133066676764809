import React, { FC, PropsWithChildren } from "react"
import styled, { css } from "styled-components";
import { Cursive, Description, Normal, TextContainer } from "../typography/typography";
interface StyleProps{
    primary?: string | undefined;
}

const TextContainerM = styled(TextContainer)`
  margin: 2rem 0;
`;

const CardContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    flex-flow: row wrap;
    margin: 2rem 0;
    @media(max-width: 1020px) {
        flex-direction: column;   
    }
`;
const Card = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    gap: 2rem;
    overflow: hidden;
    border-radius: 5px;
    :first-child {
        border-radius: 50%;
    }
    position: relative;
    text-align: center;
    color: white;
    z-index: 0;
`;
const Card2 = styled(Card)`
    :first-child {
        border-radius: 0%;
    }
    :last-child {
        border-radius: 50%;
    }
`;
const Gradient = styled.span`
    position: absolute;
    z-index: 3;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #00000098 100%);
    width: 100%;
    height: 20%;
    bottom: 0;
`;
const Img = styled.img`
    position: relative;
    z-index: 2;
    width: 17rem;
    height: 17rem;
    border-radius: 1%;
    transform-origin: 65% 75%;
    transition: transform 1s, filter .5s ease-out;
    object-fit: cover;
    :hover {
        transform: scale(1.1);
    }
    @media(max-width: 1200px) {
        width: 25rem;
        height: 25rem;
    }
    @media(max-width: 1020px) {
        width: 30rem;
        height: 30rem;       
    }
    @media(max-width: 720px) {
        :first-child {
            width: 25rem;
            height: 25rem;
        }       
    }
    @media(max-width: 625px) {
        :first-child {
            width: 20rem;
            height: 20rem;
        }
        width: 100%;
        height: 100%;       
    }
    @media(max-width: 300px) {
        :first-child {
            width: 15rem;
            height: 15rem;
        }
        width: 100%;
        height: 100%;       
    }
`;

const ImgRound = styled(Img)`
    border-radius: 50%;
    @media(max-width: 720px) {
        width: 25rem;
        height: 25rem;
    }
`;

const CardContainer2 = styled(CardContainer)`
    @media(max-width: 1020px) {
        flex-direction: column-reverse;   
    }
`; 

const Title = styled.p`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    font-size: 2rem;
`;

const Topic = styled.p`
    position: absolute;
    bottom: 16px;
    left: 16px;
    z-index: 5;
    font-size: 1.5rem;
    cursor: default;
`;
const Price = styled.p`
    position: absolute;
    bottom: 16px;
    right: 16px;
    z-index: 5;
    font-size: 1rem;
`;

const Video = styled.video`
    position: relative;
    width: 50%;
    object-fit: cover;
    border-radius: 5px;
    z-index: 2;
    transition: all 2s;
    @media(max-width: 900px) {
        width: 80%;
    }
    margin: 0 auto;
    margin-top: -10rem;
`;


const VideoContainer = styled.div`
    width: 80%;
    height: 30rem;
    position: relative;
    margin: 0 auto;
    background-image: url("https://ik.imagekit.io/wanderlust/home/back_CUfeR6d0Li.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1658835445147");
    background-size: cover;
    background-repeat: no-repeat;
    @media(max-width: 900px) {
        width: 100%;
        height: 30rem;
    }
    @media(max-width: 630px) {
        height: 25rem;
    }
`;

const TextContainerVideo = styled(TextContainer)`
    position: absolute;
    z-index: 3;
    top: -5rem;
    color: white;
`;

const Holiday: React.FC = () => {

  return (
    <>
        <TextContainerM>
            <Cursive>Choose Your</Cursive>
            <Normal>Perfect Holiday</Normal>
            <Description color="black">Take vacations, go as many places as you can. You can always make money, you can’t always make memories.</Description>
        </TextContainerM>
        <CardContainer>
            <Card><ImgRound src="https://cdn.pixabay.com/photo/2020/04/23/14/00/buddha-5082641_1280.jpg" alt=""></ImgRound><Title>Thailand</Title></Card>
            <Card><Img src="https://img.freepik.com/free-photo/wat-arun-temple-twilight-bangkok-thailand_335224-772.jpg?w=1800&t=st=1666868180~exp=1666868780~hmac=50975e2f6ae2f8e78baa2533e4bea8a148660e8331192e98fc895935e7741cfd" alt=""></Img><Topic>Bangkok</Topic><Gradient/></Card>
            <Card><Img src="https://img.freepik.com/free-photo/beautiful-girl-running-wooden-path-koh-nang-yuan-island-near-koh-tao-island-surat-thani-thailand_335224-1089.jpg?w=1800&t=st=1666868100~exp=1666868700~hmac=8d0c371bea1ba6d46c54d9ae9ff11e1805577906570b5d360e7955c733f8e50c" alt=""></Img><Topic>Koh Samui</Topic><Gradient/></Card>
            <Card><Img src="https://img.freepik.com/free-photo/beautiful-girl-sitting-boat-james-bond-island-phang-nga-thailand_335224-1222.jpg?w=1800&t=st=1666867933~exp=1666868533~hmac=099dcb1be73efeb30dd08f0f6e223c8b929b110fee9e501427d5898743710225" alt=""></Img><Topic>Phuket</Topic><Gradient/></Card>
        </CardContainer>
        <CardContainer2>
            <Card2><Img src="https://ik.imagekit.io/wanderlust/holidays/295326123_1499359173857644_2241242673744617130_n_z98hy-cO7.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1666868692059" alt=""></Img><Topic>Gili Islands</Topic><Gradient/></Card2>
            <Card2><Img src="https://img.freepik.com/free-photo/young-girl-steps-house-tree-sunrise-nusa-penida-island-bali-indonesia_335224-350.jpg?w=1800&t=st=1666868410~exp=1666869010~hmac=e373db1d174fa437ca77fb4fcb737f467a2ded7f9874852161724b2b373be595" alt=""></Img><Topic>Nusa Penida</Topic><Gradient/></Card2>
            <Card2><Img src="https://img.freepik.com/free-photo/tourist-holding-man-s-hand-leading-him-besakih-temple-bali-indonesia_335224-370.jpg?w=1800&t=st=1666868297~exp=1666868897~hmac=17d7f55dd599fc0a0e4b0a2b2cad07bb78433fbadd4c148d78f1cea391f46d62" alt=""></Img><Topic>Bali</Topic><Gradient/></Card2>
            <Card2><ImgRound src="https://cdn.pixabay.com/photo/2016/08/08/16/09/indonesia-1578647_1280.jpg" alt=""></ImgRound><Title>Indonesia</Title></Card2>
        </CardContainer2>
        <CardContainer>
            <Card><ImgRound src="https://cdn.pixabay.com/photo/2016/06/09/22/09/water-1446738_1280.jpg" alt=""></ImgRound><Title>Maldives</Title></Card>
            <Card><Img src="https://ik.imagekit.io/wanderlust/holidays/Siyam_World_DQvGA46KF.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1666868970005" alt=""></Img><Topic>Siyam World</Topic><Gradient/></Card>
            <Card><Img src="https://ik.imagekit.io/wanderlust/holidays/LUX_South_Ari_Atoll_d3SlmMxj4.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1666869041766" alt=""></Img><Topic>LUX South Ari Atoll</Topic><Gradient/></Card>
            <Card><Img src="https://ik.imagekit.io/wanderlust/holidays/soneva?ik-sdk-version=javascript-1.4.3&updatedAt=1666869599764" alt=""></Img><Topic>Soneva Jani</Topic><Gradient/></Card>
        </CardContainer>
        <div style={{margin: "5rem 0"}}/>
        <VideoContainer>
            <TextContainerVideo>
                <Cursive>Go &amp; Discover</Cursive>
                <Normal color="white">Breathtaking Cities</Normal>
                <Description color="white">Happiness consists of living each day as if it were the first day of your honeymoon and the last day of your vacation.</Description>
            </TextContainerVideo>
        </VideoContainer>
        <Video loop autoPlay muted playsInline >
            <source src="https://ik.imagekit.io/wanderlust/home/Waterfall_-_80353_ZwQmWTBvy.mp4?ik-sdk-version=javascript-1.4.3&updatedAt=1658835474938" type="video/mp4"/>
            </Video>
    </>
  )
}
export default Holiday;
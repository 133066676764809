import React from "react";
import styled from 'styled-components';

export const Cursive = styled.p`
    font-size: 2.2rem;
    font-family: 'Dancing Script', cursive;
    font-weight: 700;
    text-align: center;
    transition: all 2s;
    @media(max-width: 630px) {
        font-size: 1.5rem;
    }
`;
export const Normal = styled.p`
    font-size: 4.2rem;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    transition: all 2s;
    color:${(props)=>props.color ? props.color :"#000" };
    @media(max-width: 630px) {
        font-size: 2.5rem;
    }
`;
export const Description = styled.p`
    font-size: 1.6rem;
    font-family: 'Poppins', sans-serif;
    width: 80%;
    text-align: center;
    transition: all 2s;
    p {
        margin: 0;
    }
    color:${(props)=>props.color};
    @media(max-width: 630px) {
        width: 90%;
        font-size: 1rem;
    }
`;

export const TextContainer = styled.div`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
gap: 1rem;
flex-flow: column nowrap;
`;
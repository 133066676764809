import React, { FC, PropsWithChildren } from "react"
import styled, { css } from "styled-components";
import Slider from "react-slick";
import { graphql, useStaticQuery } from "gatsby";
import { ImLocation2 } from "react-icons/im";
import location from "../../assets/icon/adventurer.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Cursive, Description, Normal, TextContainer } from "../typography/typography";
import { MdCall } from "react-icons/md";
import { FiMail } from "react-icons/fi";
import { SiWhatsapp } from "react-icons/si";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

export const query = graphql`
{   
  plan:
  allContentfulPlans(filter: {node_locale: {eq: "en-US"}}) {
    nodes {
      desc
      price
      title
      image {
        gatsbyImageData(formats: WEBP, placeholder: BLURRED, quality: 75)
      }
    }
  }
}`;
interface StyleProps {
  primary?: string | undefined;
}
const Row = styled(Slider)`
margin: 2rem 0;
@media(max-width: 600px) {
    margin: 2rem;
}
`;

const Card = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    align-content: center;
    flex-flow: row wrap;
    transition: all 2s;
    margin: 0 15%;
    background-color: #ffffff;
    @media(max-width: 599px) {
        margin: 0;
    }
`;

const CardImage = styled(GatsbyImage)`
    object-fit: fill;
    width: 19rem;
    height: 17rem;
    border-top-left-radius: 5%;
    border-top-right-radius: 5%;
    @media(max-width: 600px) {
        width: 100%;
    }
`;

const Topic = styled.p`
    font-size: 1.5rem;
    color: black;
    width: 19rem;
    background-color: #f8f8f8;
    padding: .3rem 1rem;
    @media(max-width: 600px) {
        width: 100%;
    }
    margin: 0;
`;
const CardDescription = styled.p`
    font-size: 1rem;
    color: black;
    width: 19rem;
    padding: 0 1rem;
    height: 7rem;
    margin: 0;
    background: #f8f8f8;
    @media(max-width: 600px) {
        width: 100%;
    }
`;

const Divider = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: 3em;
    text-align: center;
    padding: .6rem 1rem;
    width: 19rem;
    font-size: 1.2rem;
    z-index: 2;
    background-color: #3fd0d4;
    color: white;
    @media(max-width: 600px) {
        width: 100%;
    }
    ::after {
        background-image: url("${location}");
        background-size: 3rem 3rem;
        background-repeat: no-repeat;
        transform: scaleX(-1);
        display: inline-block;
        width: 3rem; 
        height: 100%;
        content:"";
    }
`;

const Container = styled.div`
padding: 4rem 0;
background-color: #ffffff;
`;
const Button = styled.a`
    color: #fff;
    font-family: 'sans-serif';
    text-decoration: none;
    font-size: 1.3rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    height: 2.5rem;
    justify-content: space-around;
    margin: -2rem 0 0 0;
    padding-bottom: 2rem;
`;
const Div = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 19rem;
  justify-content: space-evenly;
  @media(max-width: 600px) {
        width: 100%;
    }
`;
const MdCall1 = styled(MdCall)`
    width: 2rem;
    height: 2rem;
    color: #0088ff;
`;

const SiMinutemailer1 = styled(FiMail)`
    width: 2rem;
    height: 2rem;
    color: #9d1331;
`;
const SiWhatsappSize = styled(SiWhatsapp)`
    width: 2rem;
    height: 2rem;
    color: #27a32e;
`;
const PerfectHoliday: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    arrows: false,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: false
        }
      }
    ]
  };
  const { plan } = useStaticQuery(query);
  return (
    <>
      <Container>
        <TextContainer>
          <Cursive>Plan The</Cursive>
          <Normal>Perfect Holiday</Normal>
          <Description color="black">Vacations mean a change of pace, a gentleness with ourselves, a time of rest and renewal, and a time to stretch ourselves and encounter new people, new lands, new ways, and new options.</Description>
        </TextContainer>
        <Row {...settings}>
          {plan.nodes.map((plans: {
            image: { gatsbyImageData: IGatsbyImageData };
            price?: string; title?: string; desc?: string;
          }) => (
            <Card>
              <CardImage image={plans.image.gatsbyImageData} alt={""}/>
              <Divider>{plans.price}</Divider>
              <Topic>{plans.title}</Topic>
              <CardDescription>{plans.desc}</CardDescription>
              <Div>
                <Button href="https://wa.me/+919691111998" target="_blank"><SiWhatsappSize /></Button>
                <Button href="tel:+919691111998" target="_blank"><MdCall1 /></Button>
                <Button href="mailto: sales@wanderlusttourism.in" target="_blank"><SiMinutemailer1 /></Button>
              </Div>
            </Card>
          ))}
        </Row>
      </Container>




    </>
  )
}
export default PerfectHoliday;
import React, { FC, PropsWithChildren } from "react"
import styled, { css } from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { Cursive, Description, Normal, TextContainer } from "../typography/typography";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/skyblue';
import '@splidejs/react-splide/css/sea-green';

// or only core styles
import '@splidejs/react-splide/css/core';
const TextContainerM = styled(TextContainer)`
  margin: 2rem 0;
`;
const Row = styled(Splide)`
    display: flex;
    flex-flow: row nowrap;
    padding: 5rem;
`;
const CardImage = styled(GatsbyImage)`
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
`;
const Container = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    align-content: center;
    p {
        margin: 1rem auto;
        width: 70%;
        @media(max-width: 700px) {
            width: 100%;
            margin: 0 auto;
        }
    }
`;

const Review: React.FC = () => {
    const { review } = useStaticQuery(query);
    const { details } = useStaticQuery(query);
    const settings = {
        rewind: true,
        width : 800,
        gap   : '1rem',
        perPage: 3,
        speed: 400,
        perMove: 3,
        drag: true,
        autoplay: true,
        type: 'loop'
    };
    const options1 = {
        renderMark: {
          [MARKS.BOLD]: (text?: any) => <b className="font-bold">{text}</b>,
        },
        renderNode: {
          [INLINES.HYPERLINK]: (node: { data: { uri?: any; }; }, children: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined) => {
            const { uri } = node.data
            return (
              <a href={uri} className="underline">
                {children}
              </a>
            )
          },
          [BLOCKS.HEADING_2]: (node?: any, children?: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined) => {
            return <h2>{children}</h2>
          },
        },
      }
    return (
        <>
            <TextContainerM>
                <Cursive>Read The Top</Cursive>
                <Normal>Travel Reviews</Normal>
                <Description color="black">What our customers say about us</Description>
            </TextContainerM>
            <Row options = {{ rewind: true,
                perPage: 1,
                speed: 400,
                perMove: 1,
                drag: true,
                autoplay: true,
                breakpoints: {
                    900: {
                        arrows: false
                    }
                },
                type: 'loop'}} >            
            {details.nodes.map((detail: {
                reviewtest1?: any;
                customerName?: string; customerPhoto: {gatsbyImageData: IGatsbyImageData}; title?: string; customerReview?: string;
            }) => (
                <SplideSlide>
                    <Container>
                    <CardImage image={detail.customerPhoto.gatsbyImageData} alt={""}></CardImage>
                    <Description style={{padding: "2rem 0 0 0", color: "black"}}>{detail.customerReview}</Description>
                    <Cursive style={{fontSize: "1.2rem", padding: "2rem 0 0 0"}}>{detail.customerName}</Cursive>
                    {/* <p>{renderRichText(detail.reviewtest1, options)}</p> */}
                </Container>
                </SplideSlide>
            ))}
            </Row>
        </>
    )
}
export default Review;

export const query = graphql`
{  
        details:
        allContentfulCustomerReview {
            nodes {
            customerName
            customerReview
            reviewtest1 {
                raw
            }
            customerPhoto {
                gatsbyImageData(layout: FULL_WIDTH, resizingBehavior: FILL)
                title
            }
            }
        }
}`;